<template>
    <div>
        <base-nav />
        <base-inner-banner img-type="smartConstructionSite"  />
            <div class="row" style="background-color:#f6f6f6;padding-bottom:63px;">
                <div class="col-lg-12">
                    <h1 class="smart-Title page-h1 mb-0">{{constructionSite.title}}</h1>
                    <h3 class="description"> {{constructionSite.description1}} </h3>
                </div>
                <div class="col-lg-12">
                    <h1 class="smart-Title page-h1 mb-0">業主現身說法</h1>
                    <div class="row" style="justify-content:center">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="embed-container">
                                <iframe  :src="constructionSite.demoVideo.video1.src" :title="constructionSite.demoVideo.video1.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                            <div>
                                <h2 class="video-title">中華工程引進科技工安，落實工地管理保障施工安全</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12" style="margin-top:60px">
                    <h3 class="description"> {{constructionSite.description2}} </h3>
                </div>
                <div class="col-lg-12" style="margin-top:60px">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 photo-container" >
                            <div class="sub-photo construction3D"></div>
                        </div>  
                    </div>
                    
                </div>
                <div class="col-lg-12">
                    <h3 class="description"> {{constructionSite.description3}} </h3>
                    <ul class="feature-list">
                        <li class="feature-key-point">
                            <p class="product-key-features-description">
                                <span class="product-key-features-title">{{constructionSite.feature.key1_title}}</span>
                                <span>{{constructionSite.feature.key1_description}}</span>
                            </p>
                        </li>
                        <li class="feature-key-point">
                            <p class="product-key-features-description">
                                <span class="product-key-features-title">{{constructionSite.feature.key2_title}}</span>
                                <span>{{constructionSite.feature.key2_description}}</span>
                            </p>
                        </li>
                        <li class="feature-key-point">
                            <p class="product-key-features-description">
                                <span class="product-key-features-title">{{constructionSite.feature.key3_title}}</span>
                                <span>{{constructionSite.feature.key3_description}}</span>
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12" style="margin-top:30px">
                    <h3 class="description"> {{constructionSite.description4}} </h3>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 photo-container" >
                            <div class="sub-photo award"></div>
                        </div>  
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 photo-container" >
                            <div class="sub-photo onsite"></div>
                        </div>  
                    </div>
                </div>

            </div>
            

            <!-- <div class="row" style="background-color:#f6f6f6;padding-bottom:63px;" >
                <h1 class="smart-Title page-h1 mb-0">業主現身說法</h1>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="embed-container">
                                <iframe  :src="constructionSite.demoVideo.video1.src" :title="constructionSite.demoVideo.video1.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </div> -->

            <div class="row" style="background-color:#f6f6f6;" >
                <div class="col-lg-12">
                    <div style="text-align:center;height:400px">
                        <h1 class="smart-Title page-h1 mb-0">訂閱電子報</h1>
                        <div class="col-lg-6 col-md-6 col-sm-12" style="margin:auto">
                            <label class="email-fill" for="email">{{$t("ContactUs.Form.Email")}}</label>
                            <span style="color:red">*</span>
                            <CInput v-if="!flag_isMobile" type="text"  id="mail" class="email-input input-size input-normal" @keyup="checkIfBlank('mail')" @change="checkIfBlank('mail')" @focusout="checkIfBlank('mail')" @focusin="checkIfBlank('mail')"  :value.sync="$v.form.mail.$model" required />
                            <CInput v-if="flag_isMobile" type="text" class="email-input input-size input-normal" id="mail" @touchend="checkIfBlank('mail')" @change="checkIfBlank('mail')" @touchcancel="checkIfBlank('mail')" @touchstart="checkIfBlank('mail')" :value.sync="$v.form.mail.$model" required />
                        </div>
                        
                        <button @click="sendEmail" :disabled="!flag_correctForm" class="call-to-act-1">
                            訂閱電子報
                        </button>
                        <div v-if="flag_formReminder" style="display:block;text-align:center;margin-top:15px">
                            <span class="success-msg">已成功寄出！</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div style="text-align:center;height:300px">
                        <h1 class="smart-Title page-h1 mb-0">讓你的工地更加智能</h1>
                        <router-link to="/constructionSite/contactUs" >
                            <button class="call-to-act-2">
                                聯絡我們
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>


        <base-footer />
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import { validationMixin } from 'vuelidate'
import { required} from "vuelidate/lib/validators"

const defaultState = () => {
    return {
        form: {
            mail: ""
        },
        constructionSite: {
            title:"城智科技使用先進的AI影像人臉辨識技術 打造金安獎智慧工地解決方案",
            description1: `城智科技的智慧工地解決方案導入多家知名建設公司，本次也與iBT智慧建築雜誌邀請客戶現身說法，說明AI智慧影像人臉識別系統運用於工地現場的實際效用，藉由科技管理，確實降低工地人員負荷。同時也協助知名工地獲得111年度金安獎殊榮。`,
            description2: `營造工地施工時的安全管理，一直是營造與建設公司需要面臨的考驗，城智科技持續的與相關業主討論，如何在運行多個案場的同時，有效預防人為的管理疏失所造成的工安意外、人事漏洞和偷竊事件，期望可以使用智慧化的手段且深入行業需求，結合人臉辨識打造一體化的智慧工地管理平台，更有效率的處理工區管理問題。`,
            description3: `城智科技的智慧工地解決方案，結合物聯網、AI影像人臉辨識、大數據、5G等技術，將各種運算資訊整合至單一智慧工地管理平台，考量到營造與建設公司總部管理多個工區的困難，以及需要符合複雜工種、班別進出的彈性操作需求，讓應用可以實際解決業主所面臨的困境，並提供最有效率和彈性的人臉辨識智慧工地方案，讓業主可以快速掌握所有工地的情況，目前已導入多個建設公司實際應用。
智慧工地往往需要控管大量的人流和不同的工安條件，並同時確保場域的安全。以下三點正是構成智慧長照所需要的核心關鍵`,
            description4: `城智科技同時於111年「科技化應用於營造工地職安衛管理觀摩會」接受勞動部職安屬表揚，肯定城智科技於工地安全管理的規劃，授予感謝狀。城智科技一步一步深耕智慧工地管理應用，期許未來能夠將更多 AI影像人臉辨識應用導入在更多領域中。`,
            feature:{
                key1_title: "自動化:",
                key1_description: "使用AI影像人臉辨識系統，自動產出數據，節省大量人力",
                key2_title: "區域管制:",
                key2_description: "使用不同物聯網裝置作區域管制，並結合門禁管理",
                key3_title: "系統整合:",
                key3_description: "整合不同應用至智慧工地管理平台，業主可以接收到最全面的資訊"
            },
            demoVideo:{
                video1:{
                    title: "中華工程引進科技工安，落實工地管理保障施工安全",
                    src: `https://www.youtube.com/embed/wV4sl3WP0Lg`
                }
            }

        },
        flag_correctForm: false,
        flag_isMobile: false,
        flag_formReminder: false
    }
}
export default {
    name:'ConstructionSite',
    metaInfo() {
        return {
            title: "城智科技使用先進的AI影像人臉辨識技術 打造金安獎智慧工地解決方案",
            meta: [
                {
                    name: 'description',
                    content: "城智科技的智慧工地解決方案導入多家知名建設公司，本次也與iBT智慧建築雜誌邀請客戶現身說法，說明AI智慧影像人臉識別系統運用於工地現場的實際效用，藉由科技管理，確實降低工地人員負荷。同時也協助知名工地獲得111年度金安獎殊榮。"
                },
                {
                    name: 'keywords',
                    content: `工地管理, 工地 管理, 人臉辨識 工地, 人臉識別`
                },
                { property: "og:url", content: "https://www.aira.com.tw/useCases/smartConstructionSite",vmid: 'og:url'},
                { property: "og:image", content: "https://www.aira.com.tw/img/smart-construction-site.3ee7d187.webp",vmid: 'og:image'},
            ]
        }
    },
    data(){
        return Object.assign({}, defaultState())
    },
    created(){
        window.scrollTo(0,0);
    },
    mounted() {
    },
    mixins: [validationMixin],
    validations: {
        form: {
            mail:{ required }
        },
        flag_correctForm: false
    },
    methods: {
        detectMob() { 
            if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)|| navigator.userAgent.match(/iPhone/i)|| navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)|| navigator.userAgent.match(/Windows Phone/i)
            ){
                this.flag_isMobile = true;
            }else {
                this.flag_isMobile = false;
            }
        },
        sendEmail(){

            const me = this;

            const contactEmail = {
                mail: me.form.mail
            }

            emailjs.send('default_service','template_3nmap5g',contactEmail,'Nrk3C1168nDCDbQ88')
            .then((res)=>{
                const me = this;
                if(res.text=="OK"){
                    me.flag_formReminder = true;
                }else{
                    me.flag_formReminder = false;
                    me.form = {
                        mail: ""
                    };
                    me.flag_correctForm = false;
                    return me.flag_correctForm
                }
            }).catch((err)=>{
                console.error(err)
            })
        },
        checkIfBlank(fieldname){
            const me = this;
            switch(fieldname){
                case "mail" : {
                    let inputElement2 = document.getElementById('mail');
                    if (me.form.mail.replace(/\s/g, "").length == 0) {
                        inputElement2.classList.add('input-alert');
                        inputElement2.classList.remove('input-normal');
                        inputElement2.style.border = '2px solid #dc3545';
                        me.flag_correctForm = false;
                        return me.flag_correctForm
                    }
                    else {
                        inputElement2.classList.remove('input-alert');
                        inputElement2.classList.add('input-normal');
                        inputElement2.style.border = '2px solid #80bdff';
                        me.flag_correctForm = true;
                        return me.flag_correctForm
                    }
                }
            }
        },

    }
}

</script>

<style lang="scss" scoped>





.email-fill {
    font-size: 25px;
}

.sub-photo {
    width: 100%;
    height: 100%;
    background-size: 62%;
    background-repeat: no-repeat;
    background-position: center;

    &.construction3D{
        background-image: url("~@/assets/img/usecase/case-study-construction-site-1.png");
    }

    &.award {
        background-image: url("~@/assets/img/usecase/case-study-construction-site-2.png");
    }
    
    &.onsite {
        background-image: url("~@/assets/img/usecase/case-study-construction-site-3.png");
    }
}

.feature-list {
    margin-left: auto;
    margin-right: auto;
    width: 61%;
    font-size: 20px;
    margin-top: 30px;
    line-height: 2.5rem;
}

.feature-key-point {
    margin-left:48px
}

.product-key-features-title{
    position: relative;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: bold;
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; overflow: hidden; 
    max-width: 100%; 
} 

.embed-container iframe, .embed-container object, .embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}

.photo-container {
    height: 800px;
}

.app-card {
    padding-top: 20px;
    background-color: #fff;
    line-height: 2rem;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.smart-Title {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.description {
    margin-left: auto;
    margin-right: auto;
    width: 61%;
    font-size: 22px;
    line-height: 2.5rem;
}

.video-title {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 2.5rem;
}









.call-to-act-1 {
    cursor: pointer;
    border: unset;
    background-color: #ff991a;
    color: #ffffff;
    height: 45px;
    width: 250px;
    border-radius:  5px;
    font-size: 30px;
    font-weight: 400px;
    text-align: center;
    transition: 0.4s;
    &:hover {
        filter: brightness(1.2);
        color: #000000;
    }
}

.call-to-act-2 {
    cursor: pointer;
    border: unset;
    background-color: #585857;
    color: #ffffff;
    height: 45px;
    width: 250px;
    border-radius:  5px;
    font-size: 30px;
    font-weight: 400px;
    text-align: center;
    transition: 0.4s;
    &:hover {
        filter: brightness(1.2);
        color: #000000;
    }
}

.email-input {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 950px) {
    .description{
        width: 77%;
        font-size: 20px;
        line-height: 3rem;
    }
    .innerBanner{
        height: 300px;
    }


    .feature-list {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        font-size: 20px;
        margin-top: 30px;
        line-height: 2.5rem;
    }

    .feature-key-point {
        margin-left:24px
    }
    
    .photo-container {
        height: 600px;
    }

    .sub-photo {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

}


@media screen and (max-width: 576px) {
    .innerBanner{
        height: 250px;
    }

    .description{
        line-height: 2rem;
        font-size: 18px;
    }

    .feature-list {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        font-size: 20px;
        margin-top: 30px;
        line-height: 2.5rem;
    }

    .feature-key-point {
        margin-left:0px
    }

    .photo-container {
        height: 400px;
    }

    .sub-photo {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }


    .video-title {
        font-size: 14px;
    }

}
</style>